import React, { useState, useRef } from "react";

// Componnets
import Property from './Property.jsx'
import Img from './Img.jsx'

const MetadataTester = () => {
  const [jsonProcessed, setJsonProcessed] = useState({});
  const [safe, setSafe] = useState(false);
  const ref = useRef(null);

  const processJson = () => {
    let userInput = JSON.parse(document.getElementById("json_file").value);
    console.log(userInput);

    setJsonProcessed(userInput);
    setSafe(true);

    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  // To copy the result to the clipboard when clicking
  const copyToClipboard = (toCopy) => {
    navigator.clipboard.writeText(toCopy);
  }

  //List for checking Solana compatibility
  const sol_props = ['symbol', 'seller_fee_basis_points', 'external_url', 'creators'];

  return (
    <div className="home">
      <h1 id="metadata_tester" style={{color:'white', marginTop:'30px'}}>METADATA TESTER</h1>

      <div className="conditional_wrapper">
        <p style={{margin:'50px 0'}}>Check how your metadata (JSON file) would look on Opensea</p>
        <div className="line"></div>
        <div className="input_box">
          <label htmlFor="json_file">Copy-Paste your JSON file here:</label>
          <textarea style={{border:"1px solid rgb(250, 242, 252)", borderRadius:"5px", backgroundColor:"rgba(214, 236, 260, 0.8)"}} name="json_file" id="json_file" cols="30" rows="10"></textarea>
        
          <p style={{fontSize:'1em', fontWeight:'300', margin:0}}>Questions? <a style={{color:'#EB5160'}} href="#notes">See here</a></p>
        
          {/* <p style={{marginTop:20, border:'1px dashed white', padding:'5px'}}>Upload file option Soon</p> */}
          <button className="button"
              onClick={(e) => {
                  e.preventDefault();
                  processJson();}}
              style={{margin:'70px 0 70px 0'}}>
            PROCESS JSON
          </button>
        </div>
        <div className="result" ref={ref}>
          <div className="result_visual">
            
            {safe ? <Img cid={jsonProcessed.image.slice(7)}/> : null}

            <button className="button result_btn">Name</button>
            <div className="name_container">
              <p>{jsonProcessed.name}</p>
            </div>

            
            <button className="button result_btn">Description</button>
            <div className="description_container">
              <p>{jsonProcessed.description}</p>
            </div>
            <button className="button result_btn">Properties</button>
            <div className="properties_container">
              {safe ? jsonProcessed.attributes.map((element, key) => {
                return <Property trait={element.trait_type} value={element.value} key={key}/>
              }) : null}
        
            </div>
          </div>

          {/* {safe ? (jsonProcessed.hasOwnProperty('symbol') ? <p className="metadata_sol"> Your Metadata IS compatible with Solana</p> : <p>Your Metadata IS NOT compatible with Solana</p>) : null} */}
        </div>

        {jsonProcessed.hasOwnProperty('symbol') ? 
        <div className="metadata_sol">
          <p>Your Metadata IS compatible with Solana</p>
        </div> 
        : 
        <div className="metadata_sol">
          <p>Your Metadata IS NOT compatible with Solana</p>
          <p>You're missing at least the following:</p>
          <br />
          {sol_props.map((element, key)=>{
            if(!jsonProcessed.hasOwnProperty(element)){
              return <div className="metadata_sol_list"> 
                <p key={key} style-={{fontSize:"0.8em", textAlign:'left'}}>{element}</p> 
              </div>
            }
          })}
          <p>See example of Solana JSON file <a href="/config/sol.pdf">here:</a> </p>
        </div>}
        
        <div style={{marginTop:60}} className="line"></div>

        <div className="notes" id="notes">
          <h2 style={{margin:'40px 0 20px 0'}}>Notes</h2>
          <div className="notes_container">
              <p>
                Metadata or JSON files assign data to the NFT, such as the image, the name, the attributes and more. This tool is a simple and fast way to check how your metadata would look like on opensea.
              </p>
              <p>Simply Copy-Paste your entire JSON file in the box above and then click PROCESS JSON.</p>
        
          </div>
        </div>
        <div className="thank_me">
          <h3>Want to Thank me?</h3>
          <p style={{fontWeight:'300', fontSize:'1em'}}>ETH, BNB, MATIC address: 0x1D4E61e7179F91bafCb5B7eA481e40647a788149</p>
          <button className="button" onClick={() => copyToClipboard("0x1D4E61e7179F91bafCb5B7eA481e40647a788149")}>Copy address</button>
        </div>
        <a style={{color:'white', textDecoration:"none", marginBottom:"20px"}} href="http://www.instagram.com/blockchain.addict" target={"_blank"} rel="noreferrer">Instagram</a>
      </div>

    </div>
  );
};

export default MetadataTester;