import React, { useState } from 'react';

// import Mint from './Mint.jsx'

function Header(){

  const [hamburger, setHamburger] = useState('menu_items');

  const openHamburger = () =>{
    switch(hamburger){
      case 'menu_items':
        setHamburger('menu_items active');
        break;
      case 'menu_items active':
        setHamburger('menu_items');
        break;
    }
    
  }

  return (
    <div className='header'>
        <div className="center_header">
          <h1 style={{color:'white'}}>NFT Tools</h1>
          <p style={{fontSize:'1.1em', color:'white', fontWeight:'300', margin:'0 0 10px 0'}}>By <a style={{textDecoration:'none', color:'transparent', fontSize:'1.2em', fontWeight:'500'}} target="blank" href="https://www.instagram.com/blockchain.addict">Blockchain.addict</a></p>
        </div>

        <div className="menu">
          <ul className={hamburger}>
            <button className='button menu_item' style={{marginBottom:5}}>
              <a href="#metadata_tester">Metadata Tester</a>
              </button>
            <button className='button menu_item'  style={{marginBottom:5}}>
              <a href="#checksum_converter">Checksum Converter</a>
              </button>
          </ul>

          <button className="hamburger" id='hamburger' onClick={openHamburger}>
          <div className="hamb_line"></div>
          <div className="hamb_line"></div>
          <div className="hamb_line"></div>
          </button>
      </div>

    </div>
  )
}

export default Header;