import React from 'react'
import MetadataTester from './MetadataTester.jsx';
import Checksum from './Checksum.jsx';

const Apps = () => {
  return (
    <div className='apps'>
        <MetadataTester/>
        <div className='separator'></div>
        <Checksum/>
    </div>
  )
}

export default Apps