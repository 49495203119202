import React from 'react';

function Property(props){
    return (
        <div style={{width:160, height:80, padding:20}} className="prop_box">
            <h4>{props.trait}</h4>
            <p>{props.value}</p>
            <p style={{fontSize:12, color:'gray'}}>X% have this trait</p>
        </div>
    )
}

export default Property;