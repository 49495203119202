import React from 'react';
import './styles/App.scss';
import { Route, Routes } from 'react-router-dom';

import Home from "./components/Home";
// import Mint from './components/Mint.jsx'


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact={true} element={<Home/>}/>
      </Routes>
    </div>
  );
}

export default App;