import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

// Components
import Header from './Header.jsx';
import Apps from './Apps.jsx';


const Home = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  // const data = useSelector((state) => state.data);
  // const [feedback, setFeedback] = useState(``);
  const [vip, setVip] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const checkBalance = () => {
    const tokenId = "13255562379598583731303172347121971023847483455838446878000866134077634249704";

    blockchain.smartContract.methods.balanceOf(blockchain.account, tokenId)
    .call()
    .then((receipt)=>{
      console.log(receipt);
      if(receipt>0){
        setVip(true);
      }
    })
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className="mint_pre_wrapper">
        <Header/>
      
        {vip ? null : <div className='mint_box'>
            {(
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <div>
                  
                    <button className="button"
                      id="connect_btn"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}>
                    CONNECT
                    </button>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <div>
                          {blockchain.errorMsg}
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <button
                      className="button"
                      onClick={(e)=>{
                        e.preventDefault();
                        checkBalance();
                      }}>
                      I Own A VIP Pass
                    </button>
                    <p style={{fontSize:'0.8em'}}>{blockchain.account}</p>
                  </>
                )}
              </>
            )}
          </div>}
      
      {vip ? <Apps/>
       : <div className="forbiden">
       <p style={{textAlign:'center', color:'white'}}>You need to hold a VIP PASS NFT To use these Tools, buy it <a href={CONFIG.MARKETPLACE_LINK} target={'_blank'} rel="noreferrer">here:</a> </p>
     <br />
     <div className="pre_description">
       <p>Tools you'll find here</p>
       <br />
       <h4>Metadata Tester:</h4>
       <p>Check how your metadata will look like on Opensea by simply Copy-pasting 
        your JSON File into the page. No need to do a test deployment anymore to see how 
        it would look.
       </p>
       <br />
       <h4>Checksum Converter:</h4>
       <p>Convert an address or list of addresses into it's checksum format, which will
        be convenient when handling Whitelists.
       </p>
       <br />
       <h3>MORE SOON</h3>

     </div>
     </div>}
    </div>
  );
};

export default Home;