import React from 'react'

function Img(props){

    const url = "https://wods.mypinata.cloud/ipfs/"

  return (
    <div>
        <img style={{width:'300px', height:'auto'}} src={url + props.cid} alt="Can't load IMG or need to replace URI" />
    </div>
  )
}

export default Img;